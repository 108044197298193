import { ENVIRONMENTS } from '@commons/data/constants';
import { datetimeToString } from '@commons/helpers/date';
import axios from 'axios';
import { DateTime } from 'luxon';
import murmurhash3_32_gc from 'murmurhash-js/murmurhash3_gc';

// Start of Selection
export const generatePassword = (length = 12) => {
  const charSets = {
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    numbers: '0123456789',
    special: '!@#$%^&*()-_=+[]{}|;:,.<>?'
  };

  const getRandomChar = set =>
    set.charAt(crypto.getRandomValues(new Uint32Array(1))[0] % set.length);

  // Ensure at least one character from each set
  const password = Object.values(charSets).map(getRandomChar);

  // Fill the remaining length with random characters from all sets
  const allChars = Object.values(charSets).join('');
  while (password.length < length) {
    password.push(getRandomChar(allChars));
  }

  // Shuffle the password using Fisher-Yates algorithm
  for (let i = password.length - 1; i > 0; i--) {
    const j = crypto.getRandomValues(new Uint32Array(1))[0] % (i + 1);
    [password[i], password[j]] = [password[j], password[i]];
  }

  return password.join('');
};

export const beatufyApiErrorMessage = (errorMessage = '') => {
  const idx = errorMessage.indexOf('ENV');
  if (idx > -1) return errorMessage.substring(0, idx - 2);
  else return errorMessage;
};

export const getBrowseInfo = async () => {
  const data = await getBrowseFingerprint();
  return {
    userAgent: navigator.userAgent,
    datetime: datetimeToString(new Date()),
    ip: data.ip,
    fingerprint: data.fingerprint
  };
};

export const getBrowseFingerprint = async () => {
  let ipInfo;
  try {
    const response = await axios({
      url: 'https://api.ipfind.com/me?auth=24fa667e-a9ec-4054-96c4-6725b4f9c3ce',
      method: 'GET'
    });
    ipInfo = response.data;
  } catch (error) {
    ipInfo = {};
  }
  return {
    fingerprint: getCustomFingerprint(navigator.userAgent, ipInfo.ip_address),
    ip: {
      ipAddress: ipInfo.ip_address,
      country: ipInfo.country,
      city: ipInfo.city
    }
  };
};

function getCustomFingerprint() {
  let bar = '|';
  let key = '';
  for (let i = 0; i < arguments.length; i++) {
    key += arguments[i] + bar;
  }
  return murmurhash3_32_gc(key, 256);
}

export const arrayBufferToJson = buffer => {
  return JSON.parse(String.fromCharCode.apply(null, new Uint8Array(buffer)));
};

export const getFullLink = (domain, subdomain, path, env) => {
  let fullLink = `${subdomain}.${domain}${path}`;
  if (env !== ENVIRONMENTS.PROD) return `${env}-${fullLink}`;
  else return fullLink;
};

export const handleCopyClick = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return true;
    }
    const dummyElement = document.createElement('textarea');
    dummyElement.value = text;
    dummyElement.style.top = '0';
    dummyElement.style.left = '0';
    dummyElement.style.position = 'fixed';
    dummyElement.style.zIndex = '-1';
    document.body.appendChild(dummyElement);
    dummyElement.focus();
    dummyElement.select();
    dummyElement.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
    return true;
  } catch (error) {
    console.error('Failed to copy text:', error);
    return false;
  }
};

/**
 * Clones an object, if the object contains DateTime properties they will be converted to DateTime objects.
 *
 * @param {Object} obj - The object to be cloned
 * @return {Object} The cloned object with DateTime properties converted
 */
export function improvedStructuredClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let clone;

  try {
    clone = structuredClone(obj);
  } catch (error) {
    // If structuredClone fails, we'll do a custom clone
    clone = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
    Object.assign(clone, obj);
  }

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value instanceof DateTime) {
        clone[key] = DateTime.fromObject(value.toObject());
      } else if (value instanceof RegExp) {
        clone[key] = new RegExp(value.source, value.flags);
        clone[key].lastIndex = value.lastIndex;
      } else if (typeof value === 'function') {
        clone[key] = value; // Functions are not cloned, just referenced
      }
    }
  }

  return clone;
}

/**
 * Checks if an object or array is empty.
 *
 * @param {Object} obj - The object or array to check.
 * @return {boolean} Returns true if the object or array is empty, otherwise false.
 */
export function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length
  );
}

/**
 * Sorts an array of objects by a specified key.
 *
 * @param {Array} array - The array to sort.
 * @param {string} key - The key to sort by.
 * @param {string} [order='asc'] - The sort order ('asc' or 'desc').
 * @return {Array} A new sorted array.
 */
export function sortBy(array, key, order = 'asc') {
  const sortFunction = (a, b) => {
    if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
    if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
    return 0;
  };

  return [...array].sort(sortFunction);
}

function deepIsEqual(value, other, seen = Object.create(null)) {
  // Base cases and performance shortcut
  if (Object.is(value, other)) return true;

  // Check for type mismatch or null values
  if (typeof value !== typeof other || value === null || other === null) {
    return false;
  }

  // Handle special numeric values
  if (typeof value === 'number' && typeof other === 'number') {
    if (isNaN(value) && isNaN(other)) return true;
    if (value === 0 && other === 0) return 1 / value === 1 / other;
  }

  if (typeof value !== 'object') return false;

  // Handle circular references
  const valueId = value.id || value._id;
  const otherId = other && (other.id || other._id);
  if (valueId && otherId) {
    if (seen[valueId] === otherId) return true;
    seen[valueId] = otherId;
  }

  // Handle Date and RegExp
  if (value instanceof Date && other instanceof Date)
    return value.getTime() === other.getTime();
  if (value instanceof RegExp && other instanceof RegExp)
    return value.toString() === other.toString();

  // Handle arrays and typed arrays
  if (Array.isArray(value) || ArrayBuffer.isView(value)) {
    if (
      (!Array.isArray(other) && !ArrayBuffer.isView(other)) ||
      value.length !== other.length
    )
      return false;
    for (let i = 0; i < value.length; i++) {
      if (!deepIsEqual(value[i], other[i], seen)) return false;
    }
    return true;
  }

  // Handle Map and Set
  if (value instanceof Map && other instanceof Map) {
    if (value.size !== other.size) return false;
    for (const [key, val] of value) {
      if (!other.has(key) || !deepIsEqual(val, other.get(key), seen)) return false;
    }
    return true;
  }
  if (value instanceof Set && other instanceof Set) {
    if (value.size !== other.size) return false;
    for (const item of value) {
      if (!other.has(item)) return false;
    }
    return true;
  }

  // Handle plain objects
  const keys = Reflect.ownKeys(value);
  if (keys.length !== Reflect.ownKeys(other).length) return false;

  for (const key of keys) {
    if (
      !Reflect.has(other, key) ||
      !deepIsEqual(Reflect.get(value, key), Reflect.get(other, key), seen)
    )
      return false;
  }

  return true;
}

/**
 * Compares two objects for deep equality.
 *
 * @param {Object} value - The first object to compare.
 * @param {Object} other - The second object to compare.
 * @return {boolean} Returns true if the objects are deeply equal, otherwise false.
 */
export function isEqual(value, other) {
  try {
    return deepIsEqual(value, other);
  } catch (error) {
    if (error instanceof RangeError) {
      // Likely a stack overflow, fall back to a less precise comparison
      return JSON.stringify(value) === JSON.stringify(other);
    }
    throw error;
  }
}

/**
 * Creates a debounced function that delays invoking func until after wait milliseconds have elapsed
 * since the last time the debounced function was invoked.
 * @param {Function} func - The function to debounce
 * @param {number} wait - The number of milliseconds to delay
 * @param {boolean} [immediate] - If true, invoke func immediately if called during the wait period
 * @returns {Function} A debounced function
 */
export function debounce(func, wait, immediate) {
  let timeout;
  let isDebouncing = false;

  function debounced(...args) {
    const later = () => {
      timeout = null;
      isDebouncing = false;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    isDebouncing = true;
    if (callNow) func.apply(this, args);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
    isDebouncing = false;
  };

  debounced.isPending = () => isDebouncing;

  return debounced;
}

/**
 * Shows a loading indicator within a specific container
 * @param {Vue} vm - Vue component instance
 * @param {HTMLElement|string} container - Container element or ref name to show loading in
 * @returns {Object|null} Loading instance or null if unable to show
 */
export function showLoading(vm, container) {
  if (!vm?.$loading?.show) {
    console.warn('Invalid Vue instance or missing $loading plugin');
    return null;
  }

  let targetContainer;
  if (typeof container === 'string') {
    // Try to get element from $refs
    targetContainer = vm.$refs?.[container]?.$el ?? vm.$refs?.[container];
  } else if (container instanceof HTMLElement) {
    // Direct element reference
    targetContainer = container;
  }

  if (!(targetContainer instanceof HTMLElement)) {
    console.warn('No valid container found for loading indicator');
    return null;
  }

  return vm.$loading.show({ container: targetContainer });
}

/**
 * Hides a loading indicator
 * @param {Object} loader - Loading instance to hide
 */
export function hideLoading(loader) {
  if (loader?.hide) {
    loader.hide();
  }
}
