var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{
    'input-group': _vm.hasIcon,
    'input-group-focus': _vm.focused,
    'has-danger': _vm.error,
    'has-success': !_vm.error && _vm.touched,
    'has-label': _vm.label
  }},[_vm._t("label",function(){return [(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label)+" "+_vm._s(_vm.required ? '*' : ''))]):_vm._e()]}),_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"name":_vm.name,"aria-describedby":"addon-right addon-left"},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]}),(_vm.error || _vm.$slots.error)?_vm._t("error",function(){return [_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))])]}):_vm._e(),_vm._t("addonRight",function(){return [(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]}),_vm._t("helperText")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }