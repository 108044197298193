var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",class:[_vm.inlineLabel ? 'd-flex flex-column' : '', _vm.readOnly ? 'soft-disable' : ''],attrs:{"id":_vm.id}},[_c('div',{staticClass:"form-group mb-0",class:{
      'input-group-focus': _vm.focused,
      'has-label': _vm.label,
      'inline-label': _vm.inlineLabel
    }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"input-label",style:(_vm.labelStyle)},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('i',{staticClass:"required-field float-left"}):_vm._e()]):_vm._e()]}),_c('div',[_c('div',{staticClass:"input-wrapper mb-0",class:{
          'input-group': _vm.hasIcon || _vm.$slots.addonRight?.length > 0
        },style:({ height: _vm.typeTag === 'textarea' ? 'auto' : '' })},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text",class:{
                'error-brdr': _vm.error,
                'success-brdr': !_vm.error && _vm.touched
              }},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [(_vm.onKeyPress)?_c(_vm.typeTag,_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"}],ref:"input",tag:"component",staticClass:"form-control",class:{
              'error-brdr': _vm.error,
              'success-brdr': _vm.hasValidation && !_vm.error && _vm.touched,
              'soft-disable': _vm.readOnly
            },attrs:{"value":_vm.value,"name":_vm.name,"disabled":_vm.readOnly || _vm.disabled},on:{"keypress":_vm.onKeyPress,"input":_vm.handleInput}},'component',_vm.$attrs,false),_vm.listeners),[(_vm.typeTag === 'textarea')?[_vm._v(_vm._s(_vm.value))]:_vm._e()],2):_c(_vm.typeTag,_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"}],ref:"input",tag:"component",staticClass:"form-control",class:{
              'error-brdr': _vm.error,
              'success-brdr': _vm.hasValidation && !_vm.error && _vm.touched,
              'soft-disable': _vm.readOnly
            },attrs:{"value":_vm.value,"name":_vm.name,"disabled":_vm.readOnly || _vm.disabled},on:{"input":_vm.handleInput}},'component',_vm.$attrs,false),_vm.listeners),[(_vm.typeTag === 'textarea')?[_vm._v(_vm._s(_vm.value))]:_vm._e()],2)]}),_vm._t("addonRight",function(){return [(_vm.hasValidation && !_vm.error && _vm.touched && !_vm.readOnly)?_c('span',{staticClass:"success-icon"},[_c('i',{staticClass:"fal fa-check-circle",attrs:{"aria-hidden":"true"}})]):(_vm.error && !_vm.readOnly)?_c('span',{staticClass:"error-icon"},[_c('i',{staticClass:"fal fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text",class:{
                'error-brdr': _vm.error,
                'success-brdr': !_vm.error && _vm.touched
              }},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]})],2)])],2),((_vm.error || _vm.$slots.error) && _vm.showErrorMsg && !_vm.readOnly)?_vm._t("error",function(){return [_c('i',{staticClass:"error-msg",class:[_vm.inlineLabel ? 'align-self-end' : '']},[_vm._v(_vm._s(_vm.error))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }